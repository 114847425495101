<template>
	<div class="europe-vise">
		<div class="europe-visea">
			<div class="">
				<div class="europe-viseb">
					<!-- 常旅客信息1 -->
					<div class="europe-headline">
						<!-- 头部一起飞标题 -->
						<div class="europe-headlinea">一起飞国际机票网</div>
						<div class="europe-headlineb iconfont icon-chalv-xiangzuo"></div>
						<div class="europe-headlinea">欧洲火车</div>
						<div class="europe-headlineb iconfont icon-chalv-xiangzuo"></div>
						<div class="europe-headlinea">中欧三地游通票</div>
						<div class="europe-headlineb iconfont icon-chalv-xiangzuo"></div>
						<div class="">资料填写</div>
						<!-- 头部一起飞标题 -->
					</div>
					<div class="europe-vised">
						<!-- 常旅客信息2 -->
						<div class="europe-viseda">1</div>
						<div class="europe-visedb">常旅客信息</div>
						<!-- 常旅客信息3 -->
					</div>

					<div class="europe-visee">
						<!-- 申请人信息1 -->
						<div class="europe-viseea">
							<!-- 申请人信息 -->
							<div class="europe-viseeb"></div>
							<div class="europe-viseeeb">
								<div class="europe-viseec">申请人信息</div>
								<div class="europe-viseed">填写说明</div>
							</div>
						</div>
						<div class="europe-viseee">
							<!-- 复选框 -->
							<el-checkbox-group v-model="checkList">
								<!-- 复选框1 -->
								<el-checkbox class="europe-viseeea" label="老大"></el-checkbox>
								<el-checkbox class="europe-viseeea" label="肖二"></el-checkbox>
								<el-checkbox class="europe-viseeea" label="张三"></el-checkbox>
								<el-checkbox class="europe-viseeea" label="李四"></el-checkbox>
								<el-checkbox class="europe-viseeea" label="王五"></el-checkbox>
								<el-checkbox class="europe-viseeea" label="赵六"></el-checkbox>
								<el-checkbox class="europe-viseeea" label="王七"></el-checkbox>
								<el-checkbox class="europe-viseeea" label="王八"></el-checkbox>
								<el-checkbox class="europe-viseeea" label="老九"></el-checkbox>
							</el-checkbox-group>
						</div>
						<div class="europe-applicat">
							<!-- 第1位申请人 -->
							<div class="europe-applicata">第1位被保人</div>
							<div class="europe-viseef"></div>
						</div>
					</div>

					<div class="europe-namezzz">
						<div class="europe-namezz">
							<div class="europe-namez">
								<!-- 申请人以内 -->
								<div class="europe-name">
									<!-- 姓名 -->
									<div class="europe-namec">
										<div class="europe-namea">姓名:</div>
										<div class="europe-nameb">*</div>
									</div>
									<div class="">
										<el-input placeholder="" v-model="input" clearable>
										</el-input>
									</div>
								</div>

								<div class="europe-name">
									<!-- 证件类型 -->
									<div class="europe-namec">
										<div class="europe-namea">证件类型:</div>
										<div class="europe-nameb">*</div>
									</div>
									<div class="">
										<el-select v-model="value" placeholder="身份证">
											<el-option v-for="item in options" :key="item.value" :label="item.label"
												:value="item.value">
											</el-option>
										</el-select>
									</div>
								</div>
								<div class="europe-name">
									<!-- 证件号码 -->
									<div class="europe-namec">
										<div class="europe-namea">证件号码:</div>
										<div class="europe-nameb">*</div>
									</div>
									<div class="">
										<el-input placeholder="" v-model="input" clearable>
										</el-input>
									</div>
								</div>
								<div class="europe-name">
									<!-- 证件有效期 -->
									<div class="europe-namec">
										<div class="europe-namea">证件有效期:</div>
										<div class="europe-nameb">*</div>
									</div>
									<div class="">
										<el-input placeholder="" v-model="input" clearable>
										</el-input>
									</div>
								</div>
								<div class="europe-name">
									<!-- 出生日期 -->
									<div class="europe-namec">
										<div class="europe-namea">出生日期:</div>
										<div class="europe-nameb">*</div>
									</div>
									<div class="">
										<el-input placeholder="" v-model="input" clearable>
										</el-input>
									</div>
								</div>

								<div class="europe-name europe-namer">
									<!-- 性别 -->
									<div class="europe-namec">
										<div class="europe-namea">性别:</div>
										<div class="europe-nameb">*</div>
									</div>
									<div class="">
										<el-radio style="margin-right: 10px;" v-model="radio" label="1">男性</el-radio>
										<el-radio v-model="radio" label="2">女性</el-radio>
									</div>
								</div>

								<div class="europe-name">
									<!-- 身份类型 -->
									<div class="europe-namec">
										<div class="europe-namea">身份类型:</div>
										<div class="europe-nameb">*</div>
									</div>
									<div class="">
										<el-input placeholder="不限" v-model="input" clearable>
										</el-input>
									</div>
								</div>

								<div class="europe-names">
									<!-- 身份类型 -->
									<div class="">
										<el-checkbox v-model="checked1">保存</el-checkbox>
									</div>
									<div class="europe-namesq">清空</div>
								</div>
							</div>
						</div>
						<div class="europe-increase">
							<div class="europe-increasea">
								<div class="europe-increaseaa">+</div>
								<div class="europe-increaseab">再增加一位</div>
							</div>
						</div>
					</div>
				</div>

				<div class="europe-journey">
					<!-- 行程 -->
					<div class="europe-journeya">
						<!-- 行程日期2 -->
						<div class="europe-viseda">2</div>
						<div class="europe-visedb">行程日期</div>
						<!-- 行程日期3 -->
					</div>
					<div class="">
						<el-input placeholder="不限" v-model="input" clearable>
						</el-input>
					</div>
					<div class="" style="margin-left: 10px;">
						(请准确填写离开中国前往欧洲日期，建议与机票日期一致)
					</div>
				</div>

				<div class="europe-journeyc">
					<div class="europe-journeyb">
						<!-- 行程 -->
						<div class="europe-journeya">
							<!-- 联系人信息2 -->
							<div class="europe-viseda">3</div>
							<div class="europe-visedb europe-relation">联系人信息</div>
							<!-- 联系人信息3 -->
							<div class="europe-relationa">
								(请准确填写联系信息，重要信息我们会通过短信，邮箱等方式通知您)
							</div>
						</div>
					</div>
					<div class="europe-relationd">
						<div class="europe-relationb">
							<!-- 联系人 -->
							<div class="europe-relationc">
								<div class="europe-namea">联系人:</div>
							</div>
							<div class="">
								<el-input placeholder="请输入联系人名字" v-model="input" clearable>
								</el-input>
							</div>
						</div>
						<div class="europe-relationb">
							<!-- 联系人 -->
							<div class="europe-relationc">
								<div class="europe-namea">联系人手机:</div>
							</div>
							<div class="">
								<el-input placeholder="请输入联系人手机" v-model="input" clearable>
								</el-input>
							</div>
						</div>
						<div class="europe-relationb">
							<!-- 联系人 -->
							<div class="europe-relationc">
								<div class="europe-namea">联系人邮箱:</div>
							</div>
							<div class="">
								<el-input placeholder="请输入联系人邮箱" v-model="input" clearable>
								</el-input>
							</div>
						</div>
						<div class="europe-relatione">
							<!-- 联系人 -->
							<div class="europe-relationc">
								<div class="europe-namea">备注:</div>
							</div>
							<div class="">
								<el-input class="europe-input" type="textarea" :rows="2" placeholder="填写备注"
									resize="none" v-model="textarea">
								</el-input>
							</div>
						</div>
					</div>
				</div>
				<div class="europe-journeyc">
					<div class="europe-journeyb">
						<!-- 行程 -->
						<div class="europe-vised europe-journeya">
							<!-- 联系人信息2 -->
							<div class="europe-viseda">4</div>
							<div class="europe-visedb europe-relation">发票配送信息</div>
							<!-- 联系人信息3 -->
						</div>
					</div>
					<div class="europe-dispatching">
						<!-- 配送 -->
						<el-radio class="europe-dispatchinga" v-model="radio1" label="1">无需配送，直接把电子行程单发到我邮箱</el-radio>
						<el-radio class="europe-dispatchinga" v-model="radio1" label="2">快递</el-radio>
						<el-radio v-model="radio1" label="3">自取（取票地址：广州市白云区黄石懂路黄园一街2号6楼）</el-radio>
						<div class="europe-dispatchingb"></div>
						<div class="europe-relationa">
							<div class="">商家现金卷</div>
						</div>
						<div class="">
							<!-- 商家现金卷选中打勾 -->
							<div class="europe-merchantt">
								<div class="europe-merchant">
									<div class="europe-merchantb" @click="show=!show">
										<div class="europe-merchanta">
											<div class="iconfont icon-querenrenwuyiwancheng europe-tick"
												v-show="show==1"></div>
											<div class="iconfont icon-chalv-duoxuankuang europe-ticka" v-show="show==0">
											</div>
										</div>
										<div class="europe-activate">
											<!-- 发票配送信息 -->
											<img src="https://3gapitest.yiqifei.com/Content/images/feihuo/visa/new_visa_index7.png"
												class="europe-activatea">
											<div class="europe-universal">
												<div class="europe-universala">
													<div class="">¥</div>
													<div class="europe-universalb">200</div>
												</div>
												<div class="europe-universalc">所有订单通用</div>
												<div class="europe-universald">有效期2018.03.22-2018.03.28</div>
											</div>
											<div class="europe-universale">已激活</div>
										</div>
									</div>
									<div class="europe-merchantc">
										<div class="">-¥100</div>
									</div>
								</div>
								<div class="europe-merchantb" @click="show1=!show1">
									<div class="europe-merchanta">
										<div class="iconfont icon-querenrenwuyiwancheng europe-tick" v-show="show1==1">
										</div>
										<div class="iconfont icon-chalv-duoxuankuang europe-ticka" v-show="show1==0">
										</div>
									</div>
									<div class="europe-activate">
										<!-- 发票配送信息 -->
										<img src="https://3gapitest.yiqifei.com/Content/images/feihuo/visa/new_visa_index7.png"
											class="europe-activatea">
										<div class="europe-universal">
											<div class="europe-universala">
												<div class="">¥</div>
												<div class="europe-universalb">200</div>
											</div>
											<div class="europe-universalc">所有订单通用</div>
											<div class="europe-universald">有效期2018.03.22-2018.03.28</div>
										</div>
										<div class="europe-universale">已激活</div>
									</div>
								</div>
								<div class="europe-money">
									<div class="europe-moneya">
										<div class="">
											<el-checkbox class="tongyi" v-model="checked2">我已阅并同意</el-checkbox>
										</div>
										<div class="europe-moneybb">保险条款和经济委托协议</div>
									</div>
									<div class="europe-moneya">
										<div class="">支付总金额：</div>
										<div class="europe-moneyb">¥1900</div>
									</div>
								</div>
							</div>
							<div class="europe-submit">
								<div class="europe-submita">提交订单</div>
							</div>
						</div>
					</div>

				</div>
			</div>



			<div class="europe-visecc">
				<div class="europe-visec">
					<!-- 已选产品 -->
					<div class="europe-product">已选产品</div>
					<div class="europe-producta">
						<img src="../../assets/air.png" class="europe-productb">
					</div>
					<div class="europe-productc">
						<div class="europe-productd">中欧三地游通票</div>
						<div class="europe-producte">Central Europe Triangle</div>
						<div class="europe-producte">产品类型: 二等座</div>
						<div class="europe-productf">
							<div class="">2018-11-07 至 2018-11-10</div>
							<div class="">共4天</div>
						</div>
					</div>
				</div>

				<div class="europe-outward">
					<div class="europe-outwarda">
						<!-- 去程 -->
						<div class="europe-outwardb">去程</div>
						<div class="europe-outwardc">
							<div class="">普罗望斯</div>
							<div class="europe-outwardd iconfont icon-jiantou"></div>
							<div class="">法兰克福</div>
						</div>
						<div class="">11月12日</div>
						<div class="">星期二</div>
						<!-- 去程 -->
					</div>
					<div class="europe-time">
						<!-- 异站换乘2次 -->
						<div class="europe-timea">08:47</div>
						<div class="europe-timeb">
							<div class="europe-timec">
								异站换乘2次
							</div>
						</div>
						<div class="europe-timea">18:47</div>
						<!-- 异站换乘2次 -->
					</div>

					<div class="europe-yinwen">
						<div class="">AEROPORT CDG 2TGV</div>
						<div class="europe-yinwena">PARIS NORD</div>
					</div>

					<div class="europe-train">
						<!-- 车次 -->
						<div class="europe-traina">
							<div class="europe-trainb">
								<div class="europe-trainb">车次:</div>
								<div class="">TGP 9890</div>
							</div>
							<div class="europe-trainb">
								<div class="europe-trainb">展品类型:</div>
								<div class="">二等座</div>
							</div>
						</div>
						<div class="europe-select">重新选择</div>
						<!-- 车次 -->
					</div>
				</div>

				<div class="europe-deal">
					<!-- 应付总额 -->
					<div class="europe-deala">
						<div class="europe-dealab">应付总额</div>
						<div class="europe-dealac">¥ 1900</div>
					</div>
					<div class="europe-dealb">
						<div class="europe-deale">成人</div>
						<div class="europe-dealac">¥ 800/人 x2</div>
					</div>
					<div class="europe-dealb">
						<div class="europe-deale">儿童</div>
						<div class="europe-dealac">¥ 100/人 x5</div>
					</div>
					<div class="europe-dealb">
						<div class="europe-deale">青年</div>
						<div class="europe-dealac">¥ 150/人 x2</div>
					</div>
					<div class="europe-dealb">
						<div class="europe-deale">老人</div>
						<div class="europe-dealac">¥ 100/人 x2</div>
					</div>
					<div class="europe-dealb">
						<div class="europe-deale">出票费</div>
						<div class="europe-dealac">¥ 100/人</div>
					</div>
					<div class="europe-dealba">
						<el-button type="text" @click="open">购票/退票规则</el-button>
					</div>
				</div>

				<div class="europe-deal">
					<!-- 总金额 -->
					<div class="europe-deala">
						<div class="europe-dealab">总金额</div>
						<div class="europe-dealac">¥ 1900</div>
					</div>
					<div class="europe-dealb">
						<div class="europe-deale">第一程</div>
						<div class="">TER 80157</div>
					</div>
					<div class="europe-dealb">
						<div class="europe-deale">成人</div>
						<div class="europe-dealac">¥ 800/人 x2</div>
					</div>
					<div class="europe-dealb">
						<div class="europe-deale">儿童</div>
						<div class="europe-dealac">¥ 100/人 x5</div>
					</div>
					<div class="europe-dealb">
						<div class="europe-deale">青年</div>
						<div class="europe-dealac">¥ 150/人 x2</div>
					</div>
					<div class="europe-dealb">
						<div class="europe-deale">老人</div>
						<div class="europe-dealac">¥ 100/人 x2</div>
					</div>
					<div class="europe-dealb">
						<div class="europe-deale">出票费</div>
						<div class="europe-dealac">¥ 100/人</div>
					</div>
					<div class="europe-dealba">
						<el-button type="text" @click="opena">购票/退票规则</el-button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				show: 0,
				show1: 0,
				checkList: [],
				input: '',
				options: [{
					value: '选项1',
					label: '户口本'
				}, {
					value: '选项2',
					label: '外籍'
				}, {
					value: '选项3',
					label: '身份证'
				}, {
					value: '选项4',
					label: '驾驶证'
				}],
				value: '',
				radio: '1',
				radio1: '1',
				textarea: '',
				checked1: false,
				checked2: false
			};
		},
		methods: {
			open() {
				this.$alert('购票内容1', '购票/退票规则', {
					confirmButtonText: '确定',
					callback: action => {
						this.$message({
							type: 'info',
							message: `action: ${ action }`
						});
					}
				});
			},
			opena() {
				this.$alert('购票内容2', '购票/退票规则', {
					confirmButtonText: '确定',
					callback: action => {
						this.$message({
							type: 'info',
							message: `action: ${ action }`
						});
					}
				});
			}
		}
	};
</script>

<style lang="scss">
	@import '../../style/Europe/booking.scss';
</style>
